<template>
  <vx-card :title="this.pageTitle">
    <template slot="actions">
      <div>
        <vs-button
          v-if="$can('create', 'static-page')"
          size="small" color="success" type="border" icon-pack="feather" icon="icon-file-plus"
          @click="createEntry()"
        >
          Nuevo
        </vs-button>
      </div>
    </template>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="entries" :hoverFlat="true">
          <template slot="thead">
            <vs-th v-if="showFeatured" sort-key="featured"/>
            <vs-th v-if="showPriority" sort-key="priority">Prioridad</vs-th>
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th sort-key="publication_status">Estado</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td v-if="showFeatured">
                <span>
                  <vs-icon
                    :icon="record.featured === true ? 'star': 'star_border'"
                    :color="record.featured === true ? 'warning': ''"
                    size="small"
                  />
                </span>
              </vs-td>
              <vs-td v-if="showPriority">
                <p class="font-medium">{{ record.priority }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.name }}</p>
              </vs-td>
              <vs-td>
                <vs-chip :color="record.publication_status | publication_status_color">
                  {{ record.publication_status | publication_status_label }}
                </vs-chip>
              </vs-td>
              <vs-td>
                <div class="flex">
                  <vx-tooltip text="Editar">
                    <vs-button
                      :disabled="!($can('retrieve', 'static-page') || $can('update', 'static-page'))"
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                      @click="retrieveEntry(record.id)"
                    />
                  </vx-tooltip>
                  <vx-tooltip v-if="showInbox" text="Inbox">
                    <vs-button
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-inbox"
                      @click="retrieveForms(record.id)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Publicar">
                    <vs-button
                      radius size="large" :color="record.in_production ? 'dark' : 'success'" type="flat"
                      icon-pack="feather" icon="icon-send"
                      :disabled="record.in_production"
                      @click="confirmCommit(record)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                      :disabled="!$can('delete', 'static-page')"
                      radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                      @click="confirmDelete(record)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>

              <template slot="expand">
                <vs-row>
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                    <vs-list class="mr-1 ml-2">
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-link"
                        title="URL Portal Privado"
                        :subtitle="record | static_page_url('staging')"
                      >
                        <div class="flex">
                          <vx-tooltip text="Ir">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-external-link"
                              @click="goToEntryPage(record, 'staging')"
                            />
                          </vx-tooltip>
                          <vx-tooltip text="Copiar">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-copy"
                              @click="copyEntryURLToClipboard(record, 'staging')"
                            />
                          </vx-tooltip>
                        </div>
                      </vs-list-item>
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-link"
                        title="URL Portal Público"
                        :subtitle="record | static_page_url('public')"
                      >
                        <div class="flex">
                          <vx-tooltip text="Ir">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-external-link"
                              @click="goToEntryPage(record, 'public')"
                            />
                          </vx-tooltip>
                          <vx-tooltip text="Copiar">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-copy"
                              @click="copyEntryURLToClipboard(record, 'public')"
                            />
                          </vx-tooltip>
                        </div>
                      </vs-list-item>
                    </vs-list>
                  </vs-col>
                </vs-row>
              </template>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import Vue from 'vue';
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import * as notificationMixins from "../../utils/notificationMixins";

  let notifier = new Notifier();
  const ENTRIES_CLIENT = new EntriesClient("static-pages");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("static-pages");

  export default {
    data() {
      return {
        pageTitle: "",
        showInbox: [
          "landings",
          "promotional_items",
          "complaints_or_queries",
          "affiliated_businesses",
          "calculators",
        ].includes(this.$route.params.category),
        showPriority: !["landings", "promotional_items"].includes(this.$route.params.category),
        showFeatured: !["landings", "promotional_items"].includes(this.$route.params.category),
        entries: [],
      };
    },

    mounted() {
      this.$vs.loading();
      this.setPageTitle(false)
        .then(() => {
          this.getEntries(false)
            .then(() => {
              this.$vs.loading.close();
            });
        });
    },

    methods: {
      async setPageTitle(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await ATTRIBUTE_OPTIONS_CLIENT.category({
          pathParameters: {categoryId: this.$route.params.category}
        })
          .then(response => {
            this.pageTitle = response.data.name;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getEntries(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await ENTRIES_CLIENT.all({
          queryStrings: {
            ...this.queryStrings,
            ...{
              institution_id: this.$route.params.institution_id,
              category_id: this.$route.params.category,
            }
          }
        })
          .then(response => {
            this.entries = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async deleteEntry(entryId) {
        if (entryId !== null && entryId !== undefined && entryId !== "") {
          this.$vs.loading();
          await ENTRIES_CLIENT.delete({pathParameters: {entryId: entryId}})
            .then(response => {
              notifier.notification("deleted");
              this.getEntries()
                .then(() => {
                  this.$vs.loading.close();
                });
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      confirmDelete(entry) {
        notifier.deleteMessage().then(result => {
          if (result.value) {
            this.deleteEntry(entry.id);
          }
        });
      },

      confirmCommit(entry) {
        notificationMixins.CommitEntryNotification.fire()
          .then(result => {
            if (result.value) {
              if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                this.$vs.loading();
                ENTRIES_CLIENT.commit({pathParameters: {entryId: entry.id}})
                  .then(response => {
                    notifier.notification("committed");
                    this.getEntries();
                  })
                  .catch(error => {
                    notifier.alertMessage("error");
                  });
                this.$vs.loading.close();
              }
            }
          });
      },

      createEntry() {
        this.$router.push({
          name: "create-static-page",
          params: {
            institution_id: this.$route.params.institution_id,
            category: this.$route.params.category,
          }
        });
      },

      retrieveEntry(entryId) {
        this.$router.push({
          name: "static-page",
          params: {
            institution_id: this.$route.params.institution_id,
            category: this.$route.params.category,
            id: entryId,
          }
        });
      },

      retrieveForms(entryId) {
        this.$router.push({
          name: "static-page-forms",
          params: {
            institution_id: this.$route.params.institution_id,
            category: this.$route.params.category,
            id: entryId
          }
        });
      },

      goToEntryPage(entry, stage) {
        window.open(Vue.filter("static_page_url")(entry, stage));
      },

      copyEntryURLToClipboard(entry, stage) {
        navigator.clipboard.writeText(Vue.filter("static_page_url")(entry, stage));
      },
    },
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
